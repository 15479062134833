define("ember-mu-transform-helpers/transforms/datetime", ["exports", "@embroider/macros/es-compat2"], function (_exports, _esCompat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let DateTimeTransform;
  {
    DateTimeTransform = (0, _esCompat.default)(require("@ember-data/serializer/-private")).DateTransform;
  }
  var _default = _exports.default = DateTimeTransform;
});