define("ember-mu-transform-helpers/transforms/email", ["exports", "@ember-data/serializer/transform", "@ember/debug"], function (_exports, _transform, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EmailTransform extends _transform.default {
    deserialize(serialized) {
      if (serialized) {
        if (serialized.match(/^mailto:/)) {
          return serialized.substring('mailto:'.length);
        } else {
          (true && (0, _debug.warn)(`Expected email URI but got ${JSON.stringify(serialized)} as value`, {
            id: 'ember-mu-transform-helpers:email'
          }));
        }
      }
      return serialized;
    }
    serialize(deserialized) {
      if (deserialized) {
        return 'mailto:' + deserialized;
      } else {
        return null;
      }
    }
  }
  _exports.default = EmailTransform;
});